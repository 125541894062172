import React from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';

function Map(props) {
  return (
    <LoadScript googleMapsApiKey={process.env.GATSBY_GMAP_STATIC_API_KEY}>
      <GoogleMap
        mapContainerStyle={{ width: props.width, height: props.height }}
        center={props.location}
        zoom={props.zoom}
      >
        <InfoWindow
          position={{
            lat: props.location.lat + 0.004,
            lng: props.location.lng,
          }}
        >
          <div>
            <p>Mentorschap Amsterdam</p>
          </div>
        </InfoWindow>
        <Marker position={props.location} />
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;
