import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import contactDraw from '../images/contact-draw.svg';

import SEO from '../components/seo';
import Map from '../components/map';
import Layout from '../components/layout';
import Icon from '../components/styled/Icon';
import Box from '../components/styled/base/Box';
import Flex from '../components/styled/base/Flex';
import ContactForm from '../components/contactform';
import Container from '../components/styled/Container';
import Typography from '../components/styled/base/Typography';

const Content = styled.div`
  & hr {
    border: 0;
    position: relative;
    height: 1px;
    margin: 2rem 0;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      height: 100%;
      background-color: black;
    }
  }

  & ul {
    list-style: none;
    margin: 0;
    padding-left: 1em;
  }

  & li {
    padding-left: 1em;

    &::before {
      content: '';
      display: block;
      transform: translate(-1.25em, 2em);
      width: 0.7em;
      height: 0.7em;
      background-color: ${({ theme }) => theme.colors.secondary};
      border-radius: 50%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
`;

const Text = styled.span`
  white-space: nowrap;
  flex: 1;
  overflow-x: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Contact = ({ data }) => {
  const {
    email,
    address,
    location,
    phone,
    fax,
    accessibility,
  } = data.contact.edges[0].node;

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Container>
        <Box mt={5} p={4} mb={3} bg="primaryLight">
          <Typography color="primary" as="h1" pl={2}>
            Neem contact op
          </Typography>
          <Flex flexWrap="wrap">
            <Flex width={[1, null, null, 1 / 2]} p={2}>
              <Icon size="1.5em" color="primary" mr={3} name="map_pin" />
              <Text>{address}</Text>
            </Flex>
            <Flex width={[1, null, null, 1 / 2]} p={2}>
              <Icon size="1.5em" color="primary" mr={3} name="phone" />
              <Text as="a" href={`tel:${phone}`}>
                {phone}
              </Text>
            </Flex>
            <Flex width={[1, null, null, 1 / 2]} p={2}>
              <Icon size="1.5em" color="primary" mr={3} name="fax" />
              <Text>{fax}</Text>
            </Flex>
            <Flex width={[1, null, null, 1 / 2]} p={2}>
              <Icon size="1.5em" color="primary" mr={3} name="mail" />
              <Text
                as="a"
                href={`mailto:${email}`}
                target="_blank"
                rel="noreferrer"
              >
                {email}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Box my={5}>
          <Typography color="primary" as="h1">
            <Icon name="map_pin" /> Location
          </Typography>
          <Map
            location={{
              lat: location.lat,
              lng: location.lon,
            }}
            zoom={13}
            width="100%"
            height={500}
          />
        </Box>
        <Box my={5}>
          <Typography color="primary" as="h1">
            <Icon name="corner_up_right" /> Bereikbaarheid
          </Typography>

          <Content
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(accessibility.json),
            }}
          />
        </Box>
        <Flex
          width="full"
          flexDirection={['column', null, 'row']}
          alignItems="center"
          justifyContent="center"
          my={4}
          boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
          p={4}
        >
          <Box px={[0, 2, 4]} pb={4} mr={3} width={[1, 1, 1 / 2]}>
            <ContactForm />
          </Box>
          <Box width={[1, 1, 1 / 2]}>
            <img width="100%" src={contactDraw} alt="contact page" />
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  {
    contact: allContentfulContactPage {
      edges {
        node {
          companyName
          email
          address
          phone
          fax
          location {
            lat
            lon
          }
          accessibility {
            json
          }
        }
      }
    }
  }
`;

export default Contact;
