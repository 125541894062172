import React, { useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import Alert from './styled/Alert';
import Box from './styled/base/Box';
import Button from './styled/Button';
import FormGroup from './styled/FormGroup';
import FormControl from './styled/FormControl';
import Typography from './styled/base/Typography';

const ContactSchema = yup.object().shape({
  fullname: yup.string().required('Naam moet worden ingevuld'),
  email: yup
    .string()
    .email('E-mail is niet geldig')
    .required('E-mail moet worden ingevuld'),
  telephone: yup.string(),
  subject: yup.string(),
  message: yup.string().required('Bericht moet worden ingevuld'),
});

const ContactForm = props => {
  const { register, errors, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    validationSchema: ContactSchema,
  });

  const [success, setSuccess] = useState(null);
  const [open, setOpen] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);

  const onSubmit = async formData => {
    setSuccess(null);

    const submission = new FormData();
    Object.keys(formData).forEach(item =>
      submission.append(item, formData[item]),
    );

    try {
      const payload = { ...formData };
      await axios.post(`${process.env.GATSBY_SERVER_URL}/contactmail`, payload);
      setSuccess('success');
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        reset();
      }, 5000);
    } catch (error) {
      setSuccess('danger');
      setServerErrors('Er is een onverwachte fout opgetreden. Probeer het later opnieuw.');
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        reset();
      }, 5000);
    }
  };

  return (
    <Box {...props}>
      <Alert
        open={open}
        onClose={() => setOpen(false)}
        mb={3}
        variant={success}
        text={
          success === 'danger' ? (
            <Typography as="p">{serverErrors}</Typography>
          ) : (
            success === 'success' && 'Bericht verstuurd!'
          )
        }
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography fontWeight="bold">
          Aanvraag informatie en/of contact.
        </Typography>
        <FormGroup>
          <label htmlFor="fullname">Naam *</label>
          <FormControl
            notValid={errors.fullname}
            name="fullname"
            ref={register}
          />
          {errors.fullname && <span>{errors.fullname.message}</span>}
        </FormGroup>
        <FormGroup>
          <label htmlFor="telephone">Telefoon</label>
          <FormControl
            notValid={errors.telephone}
            name="telephone"
            ref={register}
          />
          {errors.telephone && <span>{errors.telephone.message}</span>}
        </FormGroup>
        <FormGroup>
          <label htmlFor="email">E-mail *</label>
          <FormControl notValid={errors.email} name="email" ref={register} />
          {errors.email && <span>{errors.email.message}</span>}
        </FormGroup>
        <FormGroup>
          <label htmlFor="subject">Onderwerp</label>
          <FormControl
            notValid={errors.subject}
            name="subject"
            ref={register}
          />
          {errors.subject && <span>{errors.subject.message}</span>}
        </FormGroup>
        <FormGroup>
          <label htmlFor="message">Uw Verzoek *</label>
          <FormControl
            notValid={errors.message}
            name="message"
            ref={register}
            as="textarea"
          />
          {errors.message && <span>{errors.message.message}</span>}
        </FormGroup>
        <Typography width="70%" mx="auto" textAlign="center" fontSize={1}>
          Een van onze (vrijwillige) collega's zal binnenkort een reactie geven.
        </Typography>
        <FormGroup>
          <Button
            type="submit"
            width="full"
            height="50px"
            color="background"
            fontSize={2}
            bg="primary"
          >
            Versturen
          </Button>
        </FormGroup>
      </form>
    </Box>
  );
};

export default ContactForm;
